<template>
  <v-form @submit.prevent="forgotPassword">
    <v-sheet class="mx-auto elevation-0">
      <v-card-title class="title font-weight-regular white--text">
        {{ $vuetify.lang.t('$vuetify.general.forgotPassword') }}
      </v-card-title>

      <v-window>
        <v-window-item :value="1">
          <v-card-text>
            <v-text-field
              v-model="email"
              name="email"
              label="Email"
              type="email"
              class="text-fields-theme"
              outlined
              required
            />
            <h5 v-if="error" class="error--text">
              <v-icon small class="mr-2 error--text">mdi-alert-circle</v-icon>
              {{ error.message }}
            </h5>
            <h5 v-if="emailSent" class="success--text">
              <v-icon small class="mr-2 success--text">
                mdi-check-circle-outline
              </v-icon>
              Saatsime kirja parooli taastamiseks teele
            </h5>
          </v-card-text>
        </v-window-item>
      </v-window>
      <v-card-actions>
        <v-btn
          depressed
          text
          class="white--text rounded-10"
          @click="$router.go(-1)"
        >
          {{ $vuetify.lang.t('$vuetify.general.back') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loading.state === true"
          type="submit"
          :disabled="!email || emailSent || countdownInAction"
        >
          <span v-if="!countdownInAction">Saada</span>
          <span v-if="totalTime >= 0 && countdownInAction">
            {{ seconds }}
          </span>
        </v-btn>
        <!-- <v-spacer /> -->
      </v-card-actions>
    </v-sheet>
  </v-form>
</template>

<script>
import { firebase } from '@firebase/app'

export default {
  data() {
    return {
      email: '',
      emailSent: false,
      error: null,
      timer: null,
      totalTime: 10,
      countdownInAction: false
    }
  },
  computed: {
    loading() {
      return this.error === undefined
    },
    minutes() {
      const minutes = Math.floor(this.totalTime / 60)
      return this.padTime(minutes)
    },
    seconds() {
      const seconds = this.totalTime - this.minutes * 60
      return this.padTime(seconds)
    }
  },
  methods: {
    async forgotPassword() {
      this.error = undefined
      try {
        await firebase.auth().sendPasswordResetEmail(this.email)
        this.emailSent = true
        this.error = null
        setTimeout(() => {
          this.emailSent = false
        }, 2000)
        this.startTimer()
      } catch (error) {
        this.error = { message: 'Sellise meiliaadressiga konto puudub' }
      }
    },
    startTimer() {
      this.countdownInAction = true
      this.timer = setInterval(() => this.countdown(), 1000)
    },
    stopTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    resetTimer() {
      this.totalTime = 10
      clearInterval(this.timer)
      this.timer = null
    },
    padTime(time) {
      return (time < 10 ? '0' : '') + time
    },
    countdown() {
      if (this.totalTime >= 1) {
        this.totalTime -= 1
      } else {
        this.totalTime = 0
        this.resetTimer()
        this.countdownInAction = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$circle-length: 151px;
$check-length: 36px;

.theme--light.v-sheet {
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 36px;
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: $circle-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: $check-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#successAnimationCircle {
  stroke-dasharray: $circle-length $circle-length;
  stroke: rgba(1, 92, 137, 1);
}

#successAnimationCheck {
  stroke-dasharray: $check-length $check-length;
  stroke: rgba(1, 92, 137, 1);
}

#successAnimationResult {
  fill: rgba(1, 92, 137, 1);
  opacity: 0;
}

#successAnimation.animated {
  animation: 1s ease-out 0s 1 both scaleAnimation;

  #successAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
      0.3s linear 0.9s 1 both fadeOut;
  }

  #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
      0.3s linear 0.9s 1 both fadeOut;
  }

  #successAnimationResult {
    animation: 0.3s linear 0.9s both fadeIn;
  }
}

#replay {
  background: rgba(255, 255, 255, 0.2);
  border: 0;
  border-radius: 3px;
  bottom: 100px;
  color: #fff;
  left: 50%;
  outline: 0;
  padding: 10px 30px;
  position: absolute;
  transform: translateX(-50%);
  &:active {
    background: rgba(255, 255, 255, 0.1);
  }
}
.theme--light.v-sheet {
  background-color: rgba(255, 255, 255, 0);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 36px;
}
::v-deep .text-fields-theme .v-input__slot {
  background: #ffffff !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  border: 0 !important;
}

::v-deep .theme--light.v-btn.v-btn--disabled {
  border: 0;
}
</style>
